.react-cookie-law-dialog{
    background: linear-gradient(180deg,#202940, #202940);
    border-radius: 10px;
    color: #fff;

    top: auto !important;
    bottom: 15px !important;
    left: 0;
    padding: 10px;
    position: fixed;
    right: 0;
    box-shadow: rgb(255, 255, 255) 0px 0px 5px 2px;

    display: inline-block;
    text-align: center;
}

.react-cookie-law-select-pane {
    text-align: center;
    display: block !important;
    padding: 0 !important;
}

.react-cookie-law-msg {
    color: #fff !important;
    font-weight: 500 !important;
    font-size: 15px!important;
}

.react-cookie-law-dialog .react-cookie-law-policy {
    color: #b6b6b6 !important;
    font-weight: 300 !important;
    font-size: 15px!important;
    text-decoration: underline!important;
    margin: 0 !important;
}

.react-cookie-law-accept-all-btn {
    position: relative!important;
    box-sizing: border-box!important;
    -webkit-tap-highlight-color: transparent!important;
    outline: 0px!important;
    border: 0px!important;
    margin-left: 5px!important;
    cursor: pointer!important;
    vertical-align: middle!important;
    appearance: none!important;
    text-decoration: none!important;
    font-family: Roboto, Helvetica, Arial, sans-serif!important;
    letter-spacing: 0.02857em!important;
    min-width: 64px!important;
    display: inline-flex!important;
    -webkit-box-pack: center!important;
    justify-content: center!important;
    -webkit-box-align: center!important;
    align-items: center!important;
    font-size: 0.75rem!important;
    font-weight: 700!important;
    border-radius: 0.5rem!important;
    line-height: 1.4!important;
    text-align: center!important;
    text-transform: uppercase!important;
    user-select: none!important;
    transition: all 150ms ease-in 0s!important;
    min-height: 2.3125rem!important;
    padding: 0.5625rem 1.5rem!important;
    background-image: initial!important;
    background-position-y: initial!important;
    background-repeat: initial!important;
    background-attachment: initial!important;
    background-origin: initial!important;
    background-clip: initial!important;
    background-color: rgb(233, 30, 99)!important;
    color: rgb(255, 255, 255)!important;
    box-shadow: rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem!important;
    background-size: 150% !important;
    background-position-x: 25% !important;
}

.react-cookie-law-accept-selection-btn {

    position: relative!important;
    box-sizing: border-box!important;
    -webkit-tap-highlight-color: transparent!important;
    outline: 0px!important;
    border: 0px!important;
    margin-right:5px!important;
    cursor: pointer!important;
    vertical-align: middle!important;
    appearance: none!important;
    text-decoration: none!important;
    font-family: Roboto, Helvetica, Arial, sans-serif!important;
    letter-spacing: 0.02857em!important;
    min-width: 64px!important;
    display: inline-flex!important;
    -webkit-box-pack: center!important;
    justify-content: center!important;
    -webkit-box-align: center!important;
    align-items: center!important;
    font-size: 0.75rem!important;
    font-weight: 700!important;
    border-radius: 0.5rem!important;
    line-height: 1.4!important;
    text-align: center!important;
    text-transform: uppercase!important;
    user-select: none!important;
    transition: all 150ms ease-in 0s!important;
    min-height: 2.3125rem!important;
    padding: 0.5625rem 1.5rem!important;
    background-image: initial!important;
    background-position-y: initial!important;
    background-repeat: initial!important;
    background-attachment: initial!important;
    background-origin: initial!important;
    background-clip: initial!important;
    background-color: rgb(26, 115, 232)!important;
    color: rgb(255, 255, 255)!important;
    box-shadow: rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem!important;
    background-size: 150%!important;
    background-position-x: 25%!important;
}

.react-cookie-law-button-wrapper {
    text-align: center;
    margin: 20px 0 0 0;

}

.react-cookie-law-option-wrapper {
    display: inline-block;
    margin: 0 7px;
}