.hide-profile-skyra .discord-author-avatar {
    display: none;
}
ul {
    padding-left: 0;
}

.EmojiPickerReact.epr-dark-theme {
    --epr-dark: #202940;
    --epr-dark-emoji-variation-picker-bg-color: var(--epr-dark);
    --epr-dark-highlight-color: silver;
    --epr-dark-text-color: var(--epr-highlight-color);
    --epr-dark-hover-bg-color: #131928;
    --epr-dark-focus-bg-color: #131928;
    --epr-dark-search-input-bg-color: #2f3d60;
    --epr-dark-category-label-bg-color: #1b2338;
    --epr-dark-picker-border-color: #171f31;
    --epr-dark-bg-color: #202940;
    --epr-dark-search-input-bg-color-active: var(--epr-dark);
    --epr-dark-emoji-variation-indicator-color: #2048a2;
    --epr-dark-category-icon-active-color: #3271b7;
    --epr-dark-skin-tone-picker-menu-color: #22222295;
}
